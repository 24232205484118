import style from './Profile.module.scss';

// React
import { useEffect, useState } from 'react';

// Images
import checkMarkImg from '../../assets/img/profile/check_mark.svg';

// Router
import { Link, useNavigate } from 'react-router-dom';

// Ant Design
import { Avatar } from 'antd';
import { DownCircleOutlined, RightOutlined, UpCircleOutlined, UserOutlined } from '@ant-design/icons';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { Status } from '../../redux/my-services/types';
import { selectMyServices } from '../../redux/my-services/selectors';
import { requestMyServices } from '../../redux/my-services/asyncActions';


const Profile = () => {
    const [activeChooseSpec, setActiveChooseSpec] = useState<boolean>(false);

    // Redux
    const dispatch = useAppDispatch();

    const { status, my_services } = useSelector(selectMyServices);

    useEffect(() => {
        if (!my_services.length && status === Status.CALM) {
            dispatch(requestMyServices());
        }
    }, [my_services, dispatch, status])

    // Navigate
    const navigate = useNavigate();

    const navigateToChooseDate = (service: number) => {
        navigate(`/home/choose-date/${service}`);
    }

    return (
        <div className={style.profile}>
            <div className={style.profile_info}>
                <Avatar
                    style={{ height: '88px', width: '87px', fontSize: '40px', background: 'rgb(181 167 167 / 25%)' }}
                    icon={<UserOutlined />}
                />
                <div>
                    <ul>
                        <li className={style.profile_info_user_id}>
                            <p>1111111</p>
                        </li>
                        <li className={style.profile_info_user}>
                            <p>Dev Jedi</p>
                        </li>
                        <li className={style.profile_info_username}>
                            <p>dev_jedi</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={style.profile__priority_psychology}>
                <div className={`${style.profile__priority_psychology_block} ${activeChooseSpec ? style.profile__priority_psychology_block_active : ''}`}>
                    <div className={style.profile__priority_psychology__text}>
                        <p>Ваш приоритетный психолог</p>
                        <p>Выбрать/Поменять психолога</p>
                    </div>
                    <div className={style.profile__priority_psychology__icon}>
                        {activeChooseSpec
                            ? <UpCircleOutlined onClick={() => setActiveChooseSpec(!activeChooseSpec)} />
                            : <DownCircleOutlined onClick={() => setActiveChooseSpec(!activeChooseSpec)} />}
                    </div>
                </div>
                {activeChooseSpec && (
                    <div className={style.profile__priority_psychology_content}>
                        <button>
                            Не назначен
                            <img src={checkMarkImg} alt='check-mark-img' />
                        </button>
                        <button>
                            <p className={style.full_name}>Елена Николаевна</p>
                            <p className={style.speciality}>Психолог</p>
                        </button>
                        <button>
                            <p className={style.full_name}>Алла Раилевна</p>
                            <p className={style.speciality}>Психолог</p>
                        </button>
                        <button>
                            <p className={style.full_name}>Елена Владимировна</p>
                            <p className={style.speciality}>Психолог-сексолог</p>
                        </button>
                        <button>
                            <p className={style.full_name}>Екатерина Владимировна</p>
                            <p className={style.speciality}>Нутрициолог</p>
                        </button>
                    </div>
                )}
            </div>
            <div className={style.profile__sessions}>
                {my_services.length > 0 && (
                    my_services.map((srv, idx) => (
                        <div className={style.profile__session} key={idx}>
                            <p className={style.profile__session_title}>Услуга «{srv.verbose_name}»</p>
                            <p className={style.profile__session_desc}>Выберите дату чтобы назначить консультацию</p>
                            <button onClick={() => navigateToChooseDate(srv.services[0].id)}>Выбрать дату</button>
                        </div>
                    ))
                )}
            </div>
            <div className={style.profile__menu}>
                <Link to={'/home/documents'}>
                    <p>Документы и сертификаты</p>
                    <RightOutlined />
                </Link>
                <Link to={'/home/support'}>
                    <p>Поддержка</p>
                    <RightOutlined />
                </Link>
            </div>
        </div>
    )
}

export default Profile;